// import CSS
import styles from '../css/app.pcss';

// app main
const main = async () => {

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: '#component-container',

        components: {

        },

        data: function() {
            return {
                pageYOffset: 0
            };
        },

        computed: {
            triangleTopLeft()  { return this.pageYOffset * 1.1 },
            triangleParallax() { return this.pageYOffset * .65 }
        },

        methods: {
            handleScroll (event) {
                if( window.pageYOffset >= 300 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }

                this.pageYOffset = window.pageYOffset

                let breadcrumb = document.querySelector('nav.c-breadcrumb');
                if( breadcrumb ) {
                    if( breadcrumb.getBoundingClientRect().top <= 90 ) {
                        breadcrumb.classList.add( 'stuck' )
                    } else {
                        breadcrumb.classList.remove( 'stuck' )
                    }
                }

                let tfilter = document.querySelector('div.c-transactionFilter');
                if( tfilter ) {
                    if( tfilter.getBoundingClientRect().top <= 90 ) {
                        tfilter.classList.add( 'stuck' )
                    } else {
                        tfilter.classList.remove( 'stuck' )
                    }
                }

                let inpagenav = document.querySelector('div.c-block--inPageNavigation');
                if( inpagenav ) {
                    if( inpagenav.getBoundingClientRect().top <= 90 ) {
                        inpagenav.classList.add( 'stuck' )
                    } else {
                        inpagenav.classList.remove( 'stuck' )
                    }
                }
            },

            handleUnload( event ) {
                document.querySelector('div.loader').classList.add('unload');
            }
        },

        mounted() {
            this.pageYOffset = window.pageYOffset
        },

        created () {
            document.body.classList.add("js-loaded");
            document.querySelector('div.loader').classList.add('active');
            window.addEventListener('scroll', this.handleScroll, {passive: true});
            window.addEventListener('beforeunload', this.handleUnload);
        },
       
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll, {passive: true});
            window.removeEventListener('beforeunload', this.handleUnload);
        },
    })

    return vm;
};

// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
